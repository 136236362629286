import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from './utils/axios/axios-instance';
import useUserAccount from './utils/hooks/use-user-account/use-user-account';

const Container = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  right: 50%;
  transform: translateX(50%);
  bottom: 24px;
  min-width: 400px;
  z-index: 99999;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: row;
  width: 100%;
`;

const Button = styled.button`
  background: yellow;
  color: black;
  border: none;
  padding: 2px 8px;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Card = styled.pre`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 5px;
  color: yellow;
  font-size: 14px;
  background: #000;
  border: 8px solid yellow;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-image: repeating-linear-gradient(-55deg, #000, #000 10px, yellow 10px, yellow 20px) 10;
`;

const Paragraph = styled.p``;

const Key = styled.span`
  color: yellow;
  user-select: none;
  text-transform: uppercase;
`;

const Value = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  color: yellow;
  text-decoration: underline;

  &:hover {
    filter: brightness(0.74);
  }

  &:active {
    filter: brightness(0.32);
  }
`;

export const DebugComponent = () => {
  const accountState = useUserAccount();
  const [isToggle, setIsToggle] = useState(false);

  const { mutate, isLoading } = useMutation({
    cacheTime: -1,
    mutationFn: async () => {
      const response = await axiosInstance.get(`/admin/login-as-user/${accountState.state.userId}`, {
        headers: {
          'X-ADMIN-AUTH-TOKEN': `${process.env.REACT_APP_LOCAL_DEVTOOLS_SECRET}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data.loginUrl.replace('http://localhost:3000/login-as-user?token=', 'Barrer ');
    },
    onSuccess: (data) => {
      navigator.clipboard.writeText(data);
    },
  });

  const values = [
    { key: 'user id', value: accountState.state.userId },
    { key: 'user email', value: accountState.state.email },
    { key: 'user role', value: accountState.state.userRole },
    { key: 'app version', value: APP_VERSION },
  ];

  return (
    <Container>
      <ButtonWrapper>
        <Button onClick={() => setIsToggle((prev) => !prev)}>🧰</Button>
        <Button onClick={() => mutate()} disabled={isLoading || !process.env.REACT_APP_LOCAL_DEVTOOLS_SECRET}>
          🪪
        </Button>
      </ButtonWrapper>
      {isToggle && (
        <Card>
          {values.map((value) => {
            if (value.value === null) return null;
            return (
              <Paragraph key={value.key}>
                <Key>{value.key}: </Key>
                <Value
                  onClick={() => navigator.clipboard.writeText(value.value!)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') navigator.clipboard.writeText(value.value!);
                  }}
                >
                  {value.value}
                </Value>
              </Paragraph>
            );
          })}
        </Card>
      )}
    </Container>
  );
};
