export const borders = {
  radius: {
    sm: '3px',
    md: '5px',
    lg: '6px',
    xl: '8px',
  },
};

export type V2Borders = typeof borders;
